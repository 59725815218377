import React from "react";
import { css, Grid, Box, Heading, Checkbox, Label, Flex } from "theme-ui";
import { SectionHeading, SectionImage, SectionParagraph, SectionSubHeading, SectionImageCaption } from "../SectionElements.js";
import { OutboundLink } from "gatsby-plugin-gtag";
import { Link } from "gatsby";
import { YouTube } from "mdx-embed"
//require('../pathToMyScript/myScript') 

export default function HomeContent() {

  return (
    <div css={css({fontWeight: 300,})}>

      <div css={css({textAlign: 'center'})}>
        <h1 css={css({paddingTop: 4, paddingBottom: 1, margin: 0, fontWeight: 300,})}>
          Introducing eRezp™ the electrostatic Respirator
        </h1>
        <h2 css={css({paddingBottom: 4, paddingTop: 0, marginTop: 0, fontWeight: 300,})}>
          Comfortable like a cloth mask, effective like an N95 respirator.
        </h2>
      </div>
		  
		  <div className="row">
		    <div className="col-12 col-md-7 col-xl-7 m-3">
					<img src="/MaskLayersBase.jpg" className="img-fluid" alt="Responsive image" />
		    </div>
		    <div className="col-12 col-md-4 col-xl-4 m-3">
					<div className="d-flex flex-column align-items-start justify-content-between">
            <p>Cloth masks are comfortable and protects others but not you. Disposable masks protects you with a filter but they are not comfortable 
            and have a short duty cycle because the moisture in your breath quickly degrades the filter fibers.</p>
            <p>We combined the comfort of a cloth mask with the protection of a disposable electrostatic filter in which the inner layer protects 
            the filter and the filter protects you as well as others.</p>
            <p><a css={css({fontWeight: `normal`, textDecoration: `none`, ":hover": {textDecoration: `underline`}})} 
              href="https://www.youtube.com/watch?v=YHamaBqIzx8?&t=59s" target="_blank">
              Watch Dr. Sanjay Gupta explaining the electrostatic effect.</a>
            </p>
					</div>
		    </div>
			</div>
      {/* Meets N95 filtering requirements - image right */}
      <div className="row">
		    <div className="col-12 col-md-6 col-xl-6 m-3">
          <SectionHeading>Meets N95 filtering requirements</SectionHeading>
          <SectionParagraph>
  					<div className="d-flex flex-column align-items-start justify-content-between">    
              <p>We tested eRezp™ and these are the <a 
                css={css({fontWeight: `normal`, textDecoration: `none`, ":hover": {textDecoration: `underline`}})} 
                href="/_eRezp_Test_Report_2021-11-24.pdf" target="_blank">results</a></p>
              <p>A filter lasts about one week.</p>
            </div>
          </SectionParagraph>
          <OutboundLink 
            css={css({
              fontFamily: `heading`,
              fontWeight: `normal`,
              fontSize: 5,
              top: '-12px',
              textDecoration: `none`,
              ":hover": {
                textDecoration: `underline`,
              },
            })}
            href="https://store.theclothmask.com/product/61B2064FE23E464E62B97B8C8DE32673654D"
          >
            Buy eRezp™ Filter Pack
          </OutboundLink>
		    </div>
		    <div className="col-12 col-md-5 col-xl-5 m-3">
					<iframe width="560" height="315" 
            src="https://www.youtube.com/embed/eAdanPfQdCA" title="YouTube video player" 
            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
          </iframe>
		    </div>
			</div>    
      {/* eRezp features - image left */}
			<div className="row">	
		    <div className="col-12 col-md-5 col-xl-5 m-3">
					<img src="/MaskHome.jpg" className="img-fluid" alt="Responsive image" />
		    </div>
		    <div className="col-12 col-md-6 col-xl-6 m-3">
				  <Label><Checkbox defaultChecked={true}/>Tight fitting.</Label>
          <Label><Checkbox defaultChecked={true}/>Child size available.</Label>
				  <Label><Checkbox defaultChecked={true}/>Comfortable to wear for extended periods of time.</Label>
				  <Label><Checkbox defaultChecked={true}/>Replaceble electrostatic filter for true protection.</Label>
				  <Label><Checkbox defaultChecked={true}/>The metal nose bridge assures a secure fit.</Label>
				  <Label><Checkbox defaultChecked={true}/>Designed to prevent glasses from fogging.</Label>
				  <Label><Checkbox defaultChecked={true}/>Made in the USA 🇺🇸</Label>
				  <SectionParagraph>&nbsp;</SectionParagraph>
				  <SectionParagraph>
				    <OutboundLink css={css({
				      fontWeight: `bold`,
				      textDecoration: `none`,
				      ":hover": {
				        textDecoration: `underline`,
				      },
				    })}
				      href="https://store.theclothmask.com/product/79C25DCB2B1EFF44D19A3643960111154DAD"
				    >
				      eRezp™ is available in a variety of colors and sizes.
				    </OutboundLink>
				  </SectionParagraph>
				  <SectionParagraph>
				    Please contact&nbsp;
				    <a css={css({fontWeight: `normal`, textDecoration: `none`, ":hover": {textDecoration: `underline`}})}
				    href="mailto:support@theclothmask.com" name="ContactUs" title="Contact Us" target="_blank">
				      support 
				    </a>  
				    &nbsp;for corporate logo or large orders.
				  </SectionParagraph>
          <SectionParagraph>
            <OutboundLink 
              css={css({
                fontFamily: `heading`,
                fontWeight: `normal`,
                fontSize: 5,
                top: '-12px',
                textDecoration: `none`,
                ":hover": {
                  textDecoration: `underline`,
                },
              })}
              href="https://store.theclothmask.com/product/79C25DCB2B1EFF44D19A3643960111154DAD"
            >
              Buy eRezp™ Bundle
            </OutboundLink>
            <div>You get a respirator with a filter already in place and 3 more filters; enough for a month of usage.</div>
          </SectionParagraph>
		    </div> {/* row */}
		  </div> 
    </div>
  )
}